import { isAgent } from 'src/api/client';
import { useTreatments } from '@splitsoftware/splitio-react';
import { Split, Treatment } from 'src/constants/Split';
import { isReady } from 'src/components/SplitContext/isReady';

export const usePurchaseFlowOptimization = () => {
  const splitIsReady = isReady();
  const treatments = useTreatments([Split.FeaturePurchaseFlowOptimization]);
  const hasSplitNewPurchaseFlow = treatments[Split.FeaturePurchaseFlowOptimization].treatment === Treatment.On;
  const hasNewPurchaseFlow = hasSplitNewPurchaseFlow && splitIsReady && !isAgent;

  return {
    hasNewPurchaseFlow,
  };
};
