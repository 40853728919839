import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

import { isReady } from 'src/components/SplitContext/isReady';
import { usePurchaseFlowOptimization } from 'src/hooks/usePurchaseFlowOptimization';
import usePreventMemberCheckout from 'src/hooks/usePreventMemberCheckout';
import { SimpleFooter } from 'src/features/footer';

function PaymentInvitePage({ location }) {
  const splitIsReady = isReady();
  const { hasNewPurchaseFlow } = usePurchaseFlowOptimization();
  const profile = location.state?.profile;

  usePreventMemberCheckout();

  useEffect(() => {
    // Redirect user without feature_purchase_flow_optimization flag
    if (splitIsReady && !hasNewPurchaseFlow) {
      navigate('/checkout/information');
    }
  }, [splitIsReady, hasNewPurchaseFlow]);

  return (
    <div className="payment-confirmation color-background-light-gray">
      <div className="container container_center">
        <div className="payment-confirmation__container text-align-center">
          <div className="payment-confirmation__svg-shadow">
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <span className="visibly-hidden">Email</span>
              <path d="M10 3H39V26.5L24.5 34.5L10 27L10 3Z" fill="#D6F5FF" />
              <path
                d="M10.5 16.7002L2.5 22.5002V46.5002H46.5V22.5002L38.5 16.7002"
                stroke="#090A0B"
                stroke-width="1.5"
                stroke-miterlimit="10"
              />
              <path d="M10.5 26.9V2.5H38.5V26.9" stroke="#090A0B" stroke-width="1.5" stroke-miterlimit="10" />
              <path d="M2.5 22.5L46.5 46.5" stroke="#090A0B" stroke-width="1.5" stroke-miterlimit="10" />
              <path d="M46.5 22.5L24.5 34.5" stroke="#090A0B" stroke-width="1.5" stroke-miterlimit="10" />
              <path
                d="M18.5 12.5H30.5"
                stroke="#090A0B"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="square"
              />
              <path
                d="M18.5 20.5H30.5"
                stroke="#090A0B"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="square"
              />
            </svg>
          </div>
          <div className="inset-m" />
          <h1 className="text-heading_2 no-padding">Payment Complete</h1>
          <div className="inset-m" />
          <h2 className="text-heading_3 no-padding">Verify your email address to finish setting up your account</h2>
          <div className="inset-m" />
          {profile && (
            <p className="color-dark-1 no-padding">
              {'Please click on the link that was sent to '}
              <span className="white-space-nowrap">{profile?.email}</span>
              {' to verify your email and complete account setup.'}
            </p>
          )}
        </div>
      </div>
      <SimpleFooter />
    </div>
  );
}

export default PaymentInvitePage;
