import { useEffect, useState } from 'react';
import { navigate } from 'gatsby';

import { useAuthContext } from 'src/components/AuthProvider';
import useMembership from 'src/api/useMembership';
import useProfile from 'src/api/useProfile';

// #25209 User Redirect based on Membership Level in Auth Token
// https://dev.azure.com/hagerty/Hagerty/_workitems/edit/25209
// When going to a checkout flow page
//   HDC 1.0 membership -> Hagerty account page
//   HDC 1.5 VIP membership -> /my-account/
//   HDC 1.5 membership (current) -> /my-account/
// Else (productCode === USMEMBFREE, or just unknown product code)
//   Do NOTHING
export const shouldRedirectTo = (legacy, member, search) => {
  const params = new URLSearchParams(search);

  if (legacy) {
    return process.env.HDC_HAGERTY_ACCOUNT_PAGE;
  }

  if (member && params.get('redirect')) return `/${params.get('redirect')}/${search}`;

  if (member) {
    return '/my-account/';
  }

  return null;
};

/**
 * This hook will prevent current member from purchase new membership, they should upgrade instead.
 *
 * in `checkout/payment` use the returned function to disable this redirect after member is newly bought.
 */
export default function usePreventMemberCheckout() {
  const { isAuthenticated, loading: authLoading } = useAuthContext();
  const { data: membership, loading: membershipLoading, hasLegacyMembership } = useMembership();
  const { loading: profileLoading } = useProfile();
  const [skip, setSkip] = useState(false);

  const member = membership?.hasMembership();
  const loading = authLoading || membershipLoading || profileLoading;

  useEffect(() => {
    const to = shouldRedirectTo(hasLegacyMembership, member, location.search);

    // Not logged in, no effect
    if (loading || !isAuthenticated) return;
    if (skip) return;

    if (to && hasLegacyMembership) {
      window.location.replace(process.env.HDC_HAGERTY_ACCOUNT_PAGE);
    } else if (to) {
      navigate(to);
    }
    // else, do nothing, continue showing current page
  }, [loading, isAuthenticated, member, hasLegacyMembership]);

  return setSkip;
}
